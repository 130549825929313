interface DeleteCertificateModalProps {
    certId: string | null;
    onClose: () => void;
    onDelete: (certId: string) => void;
    placeType: string;
    siteId: string;
    thingId: string;
  }
  
  export const DeleteCertificateModal: React.FC<DeleteCertificateModalProps> = ({
    certId,
    onClose,
    onDelete,
    placeType,
    siteId,
    thingId,
  }) => (
    <div 
      className={`fixed inset-0 bg-black/50 flex items-center justify-center z-50 ${certId ? '' : 'hidden'}`}
      onClick={onClose}
    >
      <div 
        className="bg-white rounded-lg p-6 max-w-lg w-full mx-4 shadow-xl"
        onClick={e => e.stopPropagation()}
      >
        <h2 className="text-2xl text-gray-900 font-medium mb-4">Delete certificate?</h2>
        
        <div className="space-y-4">
          <p className="font-mono text-gray-600 break-all">
            {certId}
          </p>
          
          <p className="text-gray-700">
            Warning text about generating a new certificate to replace the old one
          </p>
          
          <p className="text-gray-600">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip
          </p>
        </div>
        
        <div className="mt-6 flex justify-end gap-3">
          <button
            onClick={onClose}
            className="px-4 py-2 text-gray-600 hover:text-gray-800"
          >
            Cancel
          </button>
          
          <button
            onClick={() => certId && onDelete(certId)}
            className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600 flex items-center gap-2"
          >
            Delete
            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
              <path fillRule="evenodd" d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z" clipRule="evenodd" />
            </svg>
          </button>
        </div>
      </div>
    </div>
  );