import { useThingsApi } from "api/ingestion/things";
import { useState, useCallback } from "react";
import { Certificate } from "../types/certificate";

interface CertificateTableProps {
  certificates: Certificate[];
  placeType: string;
  placeId: string;
  thingId: string;
  onCreateClick: () => void;
  onViewPolicy: (placeType: string, siteId: string, thingId: string, policy: string) => void;
  onEditPolicy: () => void;
  onRefresh?: () => void;
}

export const CertificateTable: React.FC<CertificateTableProps> = ({
  certificates,
  placeType,
  placeId,
  thingId,
  onCreateClick,
  onViewPolicy,
  onEditPolicy,
  onRefresh,
}) => {
  const { deleteCertificate } = useThingsApi();
  const [isDeleting, setIsDeleting] = useState<string | null>(null);
  const [localCertificates, setLocalCertificates] = useState(certificates);

  const [sortConfig, setSortConfig] = useState<{
    key: "id" | "expiration";
    direction: "asc" | "desc";
  }>({ key: "id", direction: "asc" });

  useState(() => {
    setLocalCertificates(certificates);
  }, [certificates]);

  const handleSort = (key: "id" | "expiration") => {
    setSortConfig({
      key,
      direction:
        sortConfig.key === key && sortConfig.direction === "asc"
          ? "desc"
          : "asc",
    });
  };

  const handleViewPolicy = useCallback((policy: string) => {
    onViewPolicy(placeType, placeId, thingId, policy);
  }, [placeType, placeId, thingId, onViewPolicy]);

  const handleDeleteClick = async (certId: string) => {
    if (!window.confirm("Are you sure you want to delete this certificate?")) {
      return;
    }

    setIsDeleting(certId);
    try {
      if (!placeType || !placeId || !thingId || !certId) {
        throw new Error('Missing required parameters for certificate deletion');
      }

      const result = await deleteCertificate(
        placeType,
        placeId,
        thingId,
        certId
      );

      if (result.success) {
        setLocalCertificates((prev) =>
          prev.filter((cert) => cert.id !== certId)
        );
        onRefresh?.();
        alert("Certificate deleted successfully");
      } else {
        const errorMessage = result.error || "Failed to delete certificate";
        console.error("Delete certificate error:", {
          error: result.error,
          certId,
          placeType,
          placeId,
          thingId
        });
        alert(errorMessage);
      }
    } catch (error) {
      console.error("Delete certificate error:", {
        error,
        certId,
        placeType,
        placeId,
        thingId
      });
    } finally {
      setIsDeleting(null);
    }
  };

  const sortedData = [...localCertificates].sort((a, b) => {
    const direction = sortConfig.direction === "asc" ? 1 : -1;

    switch (sortConfig.key) {
      case "id":
        return direction * a.id.localeCompare(b.id);
      case "expiration":
        return (
          direction *
          (new Date(a.expirationDate).getTime() -
            new Date(b.expirationDate).getTime())
        );
      default:
        return 0;
    }
  });

  const getSortIcon = (key: "id" | "expiration") => {
    if (sortConfig.key !== key) {
      return (
        <svg
          className="h-4 w-4"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M7 16V4M7 4L3 8M7 4L11 8M17 8V20M17 20L13 16M17 20L21 16"
          />
        </svg>
      );
    }
    return sortConfig.direction === "asc" ? (
      <svg
        className="h-4 w-4"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M8 7l4-4 4 4m-8 10l4 4 4-4"
        />
      </svg>
    ) : (
      <svg
        className="h-4 w-4"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M16 17l-4 4-4-4m8-10L12 3l-4 4"
        />
      </svg>
    );
  };

  const truncateCertId = (certId: string): string => {
    const id = certId.split("/").pop() || certId;
    return `${id.slice(0, 6)}...${id.slice(-6)}`;
  };

  return (
    <div>
      <div className="flex justify-end mb-4">
        <button
          onClick={onEditPolicy}
          className="flex items-center gap-2 px-4 py-1.5 mr-4 text-blue-700 bg-white border border-blue-700 rounded hover:bg-blue-50"
        >
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g id="file-pen">
              <path
                id="Vector"
                d="M10.4166 18.3337H14.9999C15.4419 18.3337 15.8659 18.1581 16.1784 17.8455C16.491 17.5329 16.6666 17.109 16.6666 16.667V5.83366L12.4999 1.66699H4.99992C4.55789 1.66699 4.13397 1.84259 3.82141 2.15515C3.50885 2.46771 3.33325 2.89163 3.33325 3.33366V11.2503M11.6666 1.66699V5.00033C11.6666 5.44235 11.8422 5.86628 12.1547 6.17884C12.4673 6.4914 12.8912 6.66699 13.3333 6.66699H16.6666M11.1482 13.0219C11.3126 12.8575 11.443 12.6624 11.5319 12.4476C11.6209 12.2329 11.6667 12.0027 11.6667 11.7702C11.6667 11.5378 11.6209 11.3076 11.5319 11.0928C11.443 10.8781 11.3126 10.683 11.1482 10.5186C10.9839 10.3542 10.7887 10.2238 10.574 10.1349C10.3592 10.0459 10.129 10.0001 9.89657 10.0001C9.66411 10.0001 9.43393 10.0459 9.21917 10.1349C9.00441 10.2238 8.80927 10.3542 8.6449 10.5186L4.4699 14.6952C4.27177 14.8933 4.12675 15.138 4.04823 15.4069L3.35073 17.7986C3.32982 17.8703 3.32856 17.9463 3.3471 18.0187C3.36564 18.091 3.40329 18.1571 3.4561 18.2099C3.50892 18.2627 3.57497 18.3003 3.64732 18.3189C3.71968 18.3374 3.79569 18.3362 3.8674 18.3152L6.25907 17.6177C6.52795 17.5392 6.77271 17.3942 6.97073 17.1961L11.1482 13.0219Z"
                stroke="#345C9B"
                strokeWidth="1.75"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </g>
          </svg>
          <span>Edit Policy</span>
        </button>
        <button
          onClick={onCreateClick}
          className="flex items-center gap-2 px-4 py-1.5 text-blue-700 bg-white border border-blue-700 rounded hover:bg-blue-50"
        >
          <span>+</span>
          <span>New Certificate</span>
        </button>
      </div>

      <div className="border-t border-[#EAEAEB] pt-4">
        <table className="w-full">
          <thead>
            <tr className="text-left">
              <th className="pb-2">
                <button
                  onClick={() => handleSort("id")}
                  className="flex items-center gap-1 text-gray-700"
                >
                  Certificate ID
                  {getSortIcon("id")}
                </button>
              </th>
              <th className="pb-2">
                <button
                  onClick={() => handleSort("expiration")}
                  className="flex items-center gap-1 text-gray-700"
                >
                  Expiration Date
                  {getSortIcon("expiration")}
                </button>
              </th>
              <th className="pb-2"></th>
            </tr>
          </thead>
          <tbody>
            {sortedData.map((item) => (
              <tr key={item.id} className="border-t border-gray-100">
                <td
                  className="py-3 text-blue-700 cursor-pointer hover:underline"
                  onClick={() => handleViewPolicy(item.policy)}
                  title="View Policy Details"
                >
                  {truncateCertId(item.id)}
                </td>
                <td className="py-3">{item.expirationDate}</td>
                <td className="py-3 text-right">
                  <button
                    className="text-red-400 hover:text-red-600 rounded-full p-1 disabled:opacity-50 disabled:cursor-not-allowed"
                    onClick={() => handleDeleteClick(item.id)}
                    disabled={isDeleting === item.id}
                    title="Delete Certificate"
                  >
                    {isDeleting === item.id ? (
                      <svg
                        className="animate-spin h-5 w-5"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <circle
                          className="opacity-25"
                          cx="12"
                          cy="12"
                          r="10"
                          stroke="currentColor"
                          strokeWidth="4"
                        ></circle>
                        <path
                          className="opacity-75"
                          fill="currentColor"
                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                        ></path>
                      </svg>
                    ) : (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-5 w-5"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path
                          fillRule="evenodd"
                          d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
                          clipRule="evenodd"
                        />
                      </svg>
                    )}
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};
