import { useState } from "react";

import { Policy } from "../types/policy";
import { PolicyDocument } from "../types/policy";
import { NewPolicyEntryModal } from "./NewPolicyEntryModal";

type EntryType = "connection" | "subscribe" | "publish";

interface PolicyDetailsModalProps {
  policy: Policy | null;
  onClose: () => void;
  onDelete: (type: "connect" | "subscribe" | "publish", value: string) => void;
  thingId: string;
  currentPolicy: PolicyDocument | string;
  onUpdate: (policy: PolicyDocument) => Promise<void>;
}

export const PolicyDetailsModal: React.FC<PolicyDetailsModalProps> = ({
  policy,
  onClose,
  onDelete,
  thingId,
  currentPolicy,
  onUpdate,
}) => {
  const [isNewEntryModalOpen, setIsNewEntryModalOpen] = useState(false);

  if (!policy) return null;

  const parsedCurrentPolicy =
    typeof currentPolicy === "string"
      ? JSON.parse(currentPolicy)
      : currentPolicy;

  const copyToClipboard = (text: string) => {
    navigator.clipboard.writeText(text).catch((err) => {
      console.error("Failed to copy text:", err);
    });
  };

  return (
    <div
      className="fixed inset-0 bg-black/50 flex items-center justify-center z-[9999]"
      onClick={onClose}
    >
      <div
        className="bg-white rounded-lg p-6 w-full max-w-4xl mx-4"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="flex justify-between items-center mb-8">
          <div>
            <div className="text-sm text-gray-600 uppercase">
              BATTERY #{thingId}
            </div>
            <h2 className="text-xl font-medium">policy_name</h2>
          </div>
          <button
            onClick={() => setIsNewEntryModalOpen(true)}
            className="px-4 py-2 text-blue-700 border border-blue-700 rounded hover:bg-blue-50 flex items-center gap-2"
          >
            <span>+</span>
            <span>New Entry</span>
          </button>
        </div>

        <NewPolicyEntryModal
          isOpen={isNewEntryModalOpen}
          onClose={() => setIsNewEntryModalOpen(false)}
          currentPolicy={parsedCurrentPolicy}
          onUpdate={onUpdate}
        />

        <div
          className="overflow-y-auto pb-20"
          style={{ maxHeight: "calc(100vh - 200px)" }}
        >
          <div className="space-y-8">
            <div>
              <h3 className="font-medium mb-4">Allowed Connection Endpoints</h3>
              <div className="space-y-2">
                {policy.connectionEndpoints.length > 0 ? (
                  policy.connectionEndpoints.map((endpoint, index) => (
                    <div
                      key={index}
                      className="bg-gray-50 rounded-lg px-4 py-3 flex items-center justify-between"
                    >
                      <span className="text-sm font-mono">{`client/${endpoint}`}</span>
                      <div className="flex gap-2">
                        <button
                          onClick={() => copyToClipboard(`client/${endpoint}`)}
                          className="text-gray-400 hover:text-gray-600"
                          title="Copy"
                        >
                          <svg
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g id="copy">
                              <path
                                id="Vector"
                                d="M2.66683 10.6663C1.9335 10.6663 1.3335 10.0663 1.3335 9.33301V2.66634C1.3335 1.93301 1.9335 1.33301 2.66683 1.33301H9.3335C10.0668 1.33301 10.6668 1.93301 10.6668 2.66634M6.66683 5.33301H13.3335C14.0699 5.33301 14.6668 5.92996 14.6668 6.66634V13.333C14.6668 14.0694 14.0699 14.6663 13.3335 14.6663H6.66683C5.93045 14.6663 5.3335 14.0694 5.3335 13.333V6.66634C5.3335 5.92996 5.93045 5.33301 6.66683 5.33301Z"
                                stroke="#77828C"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </g>
                          </svg>
                        </button>
                        <button
                          onClick={() => onDelete("connect", endpoint)}
                          className="text-red-400 hover:text-red-600"
                          title="Delete"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-5 w-5"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                          >
                            <path
                              fillRule="evenodd"
                              d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
                              clipRule="evenodd"
                            />
                          </svg>
                        </button>
                      </div>
                    </div>
                  ))
                ) : (
                  <div className="text-gray-500 text-sm">
                    No Allowed Connection Endpoints
                  </div>
                )}
              </div>
            </div>

            <div>
              <h3 className="font-medium mb-4">Allowed Subscribe Topics</h3>
              <div className="space-y-2">
                {policy.subscribeTopics.length > 0 ? (
                  policy.subscribeTopics.map((topic, index) => (
                    <div
                      key={index}
                      className="bg-gray-50 rounded-lg px-4 py-3 flex items-center justify-between"
                    >
                      <span className="text-sm font-mono">{topic}</span>
                      <div className="flex gap-2">
                        <button
                          onClick={() => copyToClipboard(topic)}
                          className="text-gray-400 hover:text-gray-600"
                          title="Copy"
                        >
                          <svg
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g id="copy">
                              <path
                                id="Vector"
                                d="M2.66683 10.6663C1.9335 10.6663 1.3335 10.0663 1.3335 9.33301V2.66634C1.3335 1.93301 1.9335 1.33301 2.66683 1.33301H9.3335C10.0668 1.33301 10.6668 1.93301 10.6668 2.66634M6.66683 5.33301H13.3335C14.0699 5.33301 14.6668 5.92996 14.6668 6.66634V13.333C14.6668 14.0694 14.0699 14.6663 13.3335 14.6663H6.66683C5.93045 14.6663 5.3335 14.0694 5.3335 13.333V6.66634C5.3335 5.92996 5.93045 5.33301 6.66683 5.33301Z"
                                stroke="#77828C"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </g>
                          </svg>
                        </button>
                        <button
                          onClick={() => onDelete("subscribe", topic)}
                          className="text-red-400 hover:text-red-600"
                          title="Delete"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-5 w-5"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                          >
                            <path
                              fillRule="evenodd"
                              d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
                              clipRule="evenodd"
                            />
                          </svg>
                        </button>
                      </div>
                    </div>
                  ))
                ) : (
                  <div className="text-gray-500 text-sm">
                    No Allowed Subscribe Topics
                  </div>
                )}
              </div>
            </div>

            <div>
              <h3 className="font-medium mb-4">Allowed Publish Topics</h3>
              <div className="space-y-2">
                {policy.publishTopics.length > 0 ? (
                  policy.publishTopics.map((topic, index) => (
                    <div
                      key={index}
                      className="bg-gray-50 rounded-lg px-4 py-3 flex items-center justify-between"
                    >
                      <span className="text-sm font-mono">{topic}</span>
                      <div className="flex gap-2">
                        <button
                          onClick={() => copyToClipboard(topic)}
                          className="text-gray-400 hover:text-gray-600"
                          title="Copy"
                        >
                          <svg
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g id="copy">
                              <path
                                id="Vector"
                                d="M2.66683 10.6663C1.9335 10.6663 1.3335 10.0663 1.3335 9.33301V2.66634C1.3335 1.93301 1.9335 1.33301 2.66683 1.33301H9.3335C10.0668 1.33301 10.6668 1.93301 10.6668 2.66634M6.66683 5.33301H13.3335C14.0699 5.33301 14.6668 5.92996 14.6668 6.66634V13.333C14.6668 14.0694 14.0699 14.6663 13.3335 14.6663H6.66683C5.93045 14.6663 5.3335 14.0694 5.3335 13.333V6.66634C5.3335 5.92996 5.93045 5.33301 6.66683 5.33301Z"
                                stroke="#77828C"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </g>
                          </svg>
                        </button>
                        <button
                          onClick={() => {onDelete("publish", topic)}}
                          className="text-red-400 hover:text-red-600"
                          title="Delete"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-5 w-5"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                          >
                            <path
                              fillRule="evenodd"
                              d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
                              clipRule="evenodd"
                            />
                          </svg>
                        </button>
                      </div>
                    </div>
                  ))
                ) : (
                  <div className="text-gray-500 text-sm">
                    No Allowed Publish Topics
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="flex justify-end">
          <button
            onClick={onClose}
            className="px-6 py-2 bg-blue-700 text-white rounded hover:bg-blue-800"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
};
