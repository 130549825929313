export const RootCertificateSection = () => (
  <div className="mt-6 flex flex-col items-center space-y-2">
    <a
      href="https://www.amazontrust.com/repository/AmazonRootCA1.pem"
      target="_blank"
      rel="noopener noreferrer"
      className="flex items-center gap-2 px-4 py-2 text-blue-700 bg-white border border-blue-700 rounded hover:bg-blue-50"
    >
      <svg className="w-4 h-4" viewBox="0 0 20 20" fill="currentColor">
        <path
          fillRule="evenodd"
          d="M3 17a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zm3.293-7.707a1 1 0 011.414 0L9 10.586V3a1 1 0 112 0v7.586l1.293-1.293a1 1 0 111.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z"
        />
      </svg>
      Download Root Certificate (RSA)
    </a>
    <a
      href="https://www.amazontrust.com/repository/AmazonRootCA3.pem"
      target="_blank"
      rel="noopener noreferrer"
      className="flex items-center gap-2 px-4 py-2 text-blue-700 bg-white border border-blue-700 rounded hover:bg-blue-50"
    >
      <svg className="w-4 h-4" viewBox="0 0 20 20" fill="currentColor">
        <path
          fillRule="evenodd"
          d="M3 17a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zm3.293-7.707a1 1 0 011.414 0L9 10.586V3a1 1 0 112 0v7.586l1.293-1.293a1 1 0 111.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z"
        />
      </svg>
      Download Root Certificate (ECC)
    </a>
    <span className="text-center text-sm text-gray-600">
      Note: Spectra only supports MQTT ingestion over TLS. Please download the
      appropriate Root CA Certificate for the Cipher Suite supported by your
      device, and configure it for verifying TLS connections.
    </span>
  </div>
);
